import { useEffect, useState } from "react";
import DoughnutChart from "../../charts/DoughnutChart";
const labelFields = [
  'Sangat terbantu',
  'Lumayan terbantu',
  'Sedikit terbantu',
  'Tidak terbantu'
]
export default function DampakBantuan({ data }) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const mapData = {}
    const rawData = {
      labels: [],
      datasets: [
        {
          label: '',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 0.8)',
            'rgba(4,135,55, 0.6)',
            'rgba(4,135,55, 0.3)',
            'rgba(4,135,55, 0.2)',
            'rgba(4,135,55, 0.1)',
          ],
          borderColor: [
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
            'rgba(4,135,55, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    labelFields.map(field => {
      mapData[field] = 0
      return field
    })
    data.map(item => {
      if (item.bantuan_dampak == 1) {
        mapData['Sangat terbantu'] += 1
      }
      if (item.bantuan_dampak == 2 ) {
        mapData['Lumayan terbantu'] += 1
      }
      if (item.bantuan_dampak == 3) {
        mapData['Sedikit terbantu'] += 1
      }
      if (item.bantuan_dampak == 4) {
        mapData['Tidak terbantu'] += 1
      }
      return item
    })
    rawData.labels = Object.keys(mapData)
    rawData.datasets[0].data = []
    rawData.labels.map(item => {
      rawData.datasets[0].data.push(((mapData[item]/data.filter(item => Number.isInteger(item["bantuan_uang"])).length)*100).toFixed(1))
      return item
    })
    setGraphData(rawData)
  }, [data]);

  return (
    <div style={{ width: '300px', height: '300px', margin:"10px auto" }}>
    <DoughnutChart
      data={graphData}
      height={10}
      width={10}
      legend={false}
    /></div>
  )
}