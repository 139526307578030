export default function Bantuan(params) {
  return (
    <div>
      <p className='mb-4 text-base'>Cara menggunakan infografis:</p>
            <div className='prose'>
            <ol>
              <li>Setiap kelompok infografis menggambarkan setiap tujuan dalam survei </li>
              <li>Tombol jenis kelamin dan kategori suku merupakan filter yang dapat digunakan jika ingin mengetahui persentase untuk masing-masing pilihan jenis kelamin dan atau kategori suku. OAP adalah Orang Asli Papua dan Non OAP adalah bukan Orang Asli Papua</li>
              <li>Setiap filter yang dipilih, maka data yang ditampilkan adalah persentase berdasarkan jumlah OAP/non OAP/laki-laki/perempuan. </li>
            </ol>
            </div>
    </div>
  )
}