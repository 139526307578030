import { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
import Card from '../components/misc/Card';
import { Link } from 'react-router-dom';
import AsalKabupaten from '../components/data-graph/konsumen-pangan/AsalKabupaten';
import AktivitasUtama from '../components/data-graph/konsumen-pangan/AktivitasUtama';
import PengeluaranPangan from '../components/data-graph/konsumen-pangan/PengeluaranPangan';
import Button from '../components/misc/Button';
import SumberPendapatan from '../components/data-graph/konsumen-pangan/SumberPendapatan';
import PerubahanPendapatan from '../components/data-graph/konsumen-pangan/PerubahanPendapatan';
import Usia from '../components/data-graph/konsumen-pangan/Usia';
import PendidikanTerakhir from '../components/data-graph/konsumen-pangan/PendidikanTerakhir';
import FrequensiMakanan from '../components/data-graph/konsumen-pangan/FrequensiMakanan';
import AdaptasiCovid from '../components/data-graph/konsumen-pangan/AdaptasiCovid';
import PerubahanKonsumsi from '../components/data-graph/konsumen-pangan/PerubahanKonsumsi';
import PerubahanKonsumsi2 from '../components/data-graph/konsumen-pangan/PerubahanKonsumsi2';
import KegiatanEkonomiPintar from '../components/data-graph/konsumen-pangan/KegiatanEkonomiPintar';
import KegiatanTransaksiMakanan from '../components/data-graph/konsumen-pangan/KegiatanTransaksiMakanan';
import KegiatanMetodeMakanan from '../components/data-graph/konsumen-pangan/KegiatanMetodeMakanan';
import AdaptasiKeluarga1 from '../components/data-graph/konsumen-pangan/AdaptasiKeluarga1';
import AdaptasiKeluarga2 from '../components/data-graph/konsumen-pangan/AdaptasiKeluarga2';
import KondisiMembeliMakanan from '../components/data-graph/konsumen-pangan/KondisiMembeliMakanan';
import BahanBakarBar from '../components/data-graph/konsumen-pangan/BahanBakarBar';
import BahanBakarDonut from '../components/data-graph/konsumen-pangan/BahanBakarDonut';
import FrekuensiBar from '../components/data-graph/konsumen-pangan/FrekuensiBar';
import FrekuensiMedia from '../components/data-graph/konsumen-pangan/FrekuensiMedia';
import PemberiBantuan from '../components/data-graph/konsumen-pangan/PemberiBantuan';
import BentukBantuan from '../components/data-graph/konsumen-pangan/BentukBantuan';
import DampakBantuan from '../components/data-graph/konsumen-pangan/DampakBantuan';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import LoadingOverlay from 'react-loading-overlay';
import { ClipLoader } from 'react-spinners';

export default function SurveyProdusenPangan() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filterJk, setFilterJk] = useState(null) // perempuan
  const [filterSuku2, setFilterSuku2] = useState(null) // OAP


  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };
  useEffect(() => { (async() => {
    const f = await fetch("/Final_DB_Survey 3_PB.xlsx");
    const ab = await f.arrayBuffer();
    const wb = read(ab);
    const ws = wb.Sheets[wb.SheetNames[1]];
    const rawData = utils.sheet_to_json(ws).filter(item => {
      return item.id_urut !== undefined
    });
    console.log('raw data: ', rawData)
    setLoading(false)
    setData(rawData)
  })(); }, [])

  useEffect(() => {
    const rawData = data.filter(item => {
      // return item.sex === filterJk && item.kategori_suku_dua === filterSuku2
      if (filterJk && filterSuku2) {
        return item.sex === filterJk && item.kategori_suku_dua === filterSuku2
      } else if (filterJk && !filterSuku2) {
        return item.sex === filterJk
      } else if (!filterJk && filterSuku2) {
        return item.kategori_suku_dua === filterSuku2
      } else {
        return item
      }
    })
    console.log('filtered data: ', rawData.length)
    setFilteredData(rawData)
  }, [data, filterJk, filterSuku2]);

  return (
    <LoadingOverlay
    active={loading}
    spinner={<ClipLoader color="#36d7b7" size="70"/>}
    >
      <h2 className="text-stone-800 mb-[25px] text-[39px] font-bold">Survei Konsumen Pangan di Provinsi Papua Barat</h2>
      <div className='xl:flex w-full mb-[77px]'>
        <div className="w-full text-neutral-700 mb-8 xl:mb-0 text-[17px]  leading-7 xl:w-[70%]">
          <p>
          Survei ini diperuntukkan bagi konsumen bahan pangan/makanan segar dan olahan yang berusia diatas 18 tahun. Survei ini bertujuan agar pemerintah dapat mengetahui hal berikut:
          </p>
          <ol>
            <li>Perilaku konsumsi pangan masyarakat Papua Barat, sebelum dan sesudah pandemi COVID-19</li>
            <li>Lingkungan pangan masyarakat Papua Barat, sebelum dan sesudah pandemi COVID-19 </li>
          </ol>
          <p>
          Keluaran dari survei ini diharapkan dapat memberikan informasi kepada pemerintah sehingga dapat merencanakan kebijakan/program yang akan memperbaiki sistem pangan Papua Barat menuju pangan sehat berkelanjutan.
          </p>
          <p>Survei ini dilaksanakan secara online pada bulan Februari sampai dengan Mei 2021. Kami mengedarkan tautan survei online melalui grup chat, saluran media sosial, dan mengirimkan surat resmi ke lembaga terkait pangan dan pertanian untuk meminta mereka membantu mengedarkan tautan tersebut. Total responden dalam survei konsumen pangan sebanyak 407 responden (OAP=234 dan Non OAP=173; Laki-laki=188 dan Perempuan=219). 
          </p>
        </div>
        <div className='w-full text-black text-sm font-normal leading-[25px] xl:pl-[80px] xl:w-[30%]'>
          <Tooltip 
          id="my-tooltip"
          className={'opaque'}
          style={{ backgroundColor: "#ffffff", color: "#222", opacity: 1 }}>
            <p className='mb-4 text-base'>Cara menggunakan infografis:</p>
            <div className='prose'>
            <ol>
              <li>Setiap kelompok infografis menggambarkan setiap tujuan dalam survei </li>
              <li>Tombol jenis kelamin dan kategori suku merupakan filter yang dapat digunakan jika ingin mengetahui persentase untuk masing-masing pilihan jenis kelamin dan atau kategori suku. OAP adalah Orang Asli Papua dan Non OAP adalah bukan Orang Asli Papua</li>
              <li>Setiap filter yang dipilih, maka data yang ditampilkan adalah persentase berdasarkan jumlah OAP/non OAP/laki-laki/perempuan. </li>
            </ol>
            </div>
          </Tooltip>
          <button className="button w-full" data-tooltip-id="my-tooltip">PANDUAN PENGGUNAAN</button>
        </div>
      </div>

      <div className='xl:flex w-full gap-x-12 py-8 mb-8 sticky bg-[#F5F5F5] top-[105px] md:top-[72px] z-10 border-t border-t-zinc-300'>
        <div className='flex gap-4 mr-24'>
          <div className='font-bold'>Suku</div>
          <div className='form-control'>
            <input checked={filterSuku2 === 'Non OAP'} className='mr-1' type='radio' name='suku' id='non-oap' value='Non OAP' onChange={(e) => setFilterSuku2(e.target.value)} />
            <label htmlFor="non-oap">Non OAP</label>
          </div>
          <div className='form-control'>
            <input checked={filterSuku2 === 'OAP'} className='mr-1' type='radio' name='suku' id='oap' value='OAP' onChange={(e) => setFilterSuku2(e.target.value)} />
            <label htmlFor="oap">OAP</label>
          </div>
        </div>
        <div className='flex gap-4'>
          <div className='mr-1 font-bold'>Jenis kelamin</div>
          <div className='form-control'>
            <input checked={filterJk === 'Laki-laki'} className='mr-1' type='radio' name='filter-jk' id='Laki-laki' value='Laki-laki' onChange={(e) => setFilterJk(e.target.value)} />
            <label htmlFor="Laki-laki">Laki-laki</label>
          </div>
          <div className='form-control'>
            <input checked={filterJk === 'Perempuan'} className='mr-1' type='radio' name='filter-jk' id='Perempuan' value='Perempuan' onChange={(e) => setFilterJk(e.target.value)} />
            <label htmlFor="Perempuan">Perempuan</label>
          </div>
        </div>
        <div className='grow'></div>
        {
          (filterJk || filterSuku2) &&
          <Button
              style={{ padding: '10px', fontSize: '10px' }}
              onClick={() => {
                setFilterJk(null)
                setFilterSuku2(null)
              }
            }>
            Reset
          </Button>
        }
      </div>

      {!loading && 
        <>
          <div className='text-stone-800 text-lg font-bold leading-[18px] mb-[34px]' style={{fontSize: 22, color : '#013b09'}}>
            Demografi
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[33%] xl:mb-0 mb-8 w-full' title='Asal Kabupaten*'>
              <AsalKabupaten data={filteredData} />
                <div className='prose mt-4' style={{fontSize: 12}}>
                  *: Asal kabupaten merupakan tempat tinggal responden saat wawancara.
                </div>
            </Card>
            <Card classes='xl:w-[30%] xl:mb-0 mb-8 w-full' title='Usia (dalam tahun)'>
              <div className='mt-8'></div>
              <Usia data={filteredData} />
            </Card>
            <Card classes='xl:w-[40%] xl:mb-0 mb-8 w-full' title='Pendidikan Terakhir'>
              <PendidikanTerakhir data={filteredData} />
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl xl:mb-0 mb-8 w-full' title='Aktivitas dan Pendapatan'>
            <div className='xl:flex w-full'>
                <div  style={{flex: 1}}>
                  <h5 className="text-black leading-[25px] text-center mb-4">Aktivitas Utama</h5>
                  <AktivitasUtama data={filteredData} />
                  <div className='prose mt-4' style={{fontSize: 12}}>
                    *: terdiri dari pekerja perusahaan, kantor pemerintah dan non pemerintah.
                  </div>
                </div>
                <div  style={{flex: 1}}>
                  <h5 className="text-black leading-[25px] text-center">Sumber Pendapatan*</h5>
                  <SumberPendapatan data={filteredData} />
                  <div className='prose mt-4' style={{fontSize: 12}}>
                    *: Sumber pendapatan responden dapat lebih dari satu.
                  </div>
                </div>
                <div  style={{flex: 1}}>
                  <h5 className="text-black leading-[25px] text-center">Perubahan Pendapatan</h5>
                  <PerubahanPendapatan data={filteredData} />
                </div>
              </div>
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <div className='xl:flex w-full'>
                <Card classes='xl:w-[100%]  xl:mb-0 mb-8' title='Pengeluaran Pangan (selama satu minggu dalam rupiah)'>
                  <PengeluaranPangan data={filteredData} />
                  <div className='prose mt-4' style={{fontSize: 12, width: '100%', maxWidth: '100%'}}>
                    *: Pengeluaran pangan sebelum COVID-19 adalah kisaran jumlah uang untuk membeli makanan bagi responden sendiri selama satu minggu di bulan Februari 2020.
                    <br></br>**: Pengeluaran pangan setelah COVID-19 adalah kisaran jumlah uang untuk membeli makanan bagi responden sendiri selama satu minggu terakhir.
                  </div>
                </Card>
              </div>
          </div>

          <div className='text-stone-800 text-lg font-bold leading-[18px] mb-[34px]' style={{fontSize: 22, color : '#013b09'}}>
            Perilaku Konsumsi Pangan
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[70%] xl:mb-0 mb-8 w-full' title='Dampak COVID-19 terhadap Jumlah* dan Jenis Makanan yang Dikonsumsi'>
              <FrequensiMakanan data={filteredData} />
                <div className='prose mt-4' style={{fontSize: 12, maxWidth: '100%'}}>
                  *: Dampak COVID-19 terhadap jumlah dan frekuensi makanan yang dikonsumsi responden
                  <br></br>**: Jumlah responden yang menjawab "Dampak COVID-19 terhadap Jenis Makanan yang Dikonsumsi" adalah sebanyak 401 responden (OAP=229 dan Non OAP=172; Laki-laki=185 dan Perempuan=216).
                </div>
            </Card>
            <Card classes='xl:w-[30%] xl:mb-0 mb-8 w-full' title='Tingkat Adaptasi terhadap COVID-19 dalam Mendapatkan Makanan'>
              <AdaptasiCovid data={filteredData} />
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl xl:mb-0 mb-8 w-full' title='Perubahan Jumlah Konsumsi Makanan selama COVID-19'>
            <div className='xl:flex w-full'>
                <div  style={{flex: 1}}>
              <PerubahanKonsumsi data={filteredData} />
              </div>
              <div  style={{flex: 1}}>
              <PerubahanKonsumsi2 data={filteredData} />
              </div>
              </div>
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[50%] xl:mb-0 mb-8 w-full'>
              <div className='block xl:flex gap-8 w-full'>
                  {/* <img src="/Picture2.jpg" alt="image" /> */}
                <blockquote className='prose'>
                Seorang Petani perempuan dari Pegunungan Arfak beropini terkait <strong>gambaran makanan ideal</strong> dengan mengatakan: <i>"kami makan dari hasil kebun dan hutan kami, kalau ingin makanan lain itu berarti kami ke kota, jadi menurut kami tong punya makanan dikampung dari hasil kebun, (keladi, kentang, pisang, dan sayur) sudah cukup untuk kami"</i>
                </blockquote>
                </div>
            </Card>
            <Card classes='xl:w-[50%] xl:mb-0 mb-8 w-full'>
              <div className='block xl:flex gap-8 w-full'>
                  {/* <img src="/Picture2.jpg" alt="image" /> */}
                <blockquote className='prose'>
                Seorang Pekerja laki-laki dari Manokwari juga beropini: <i>"bagi saya dan keluarga, pangan lokal lebih sehat dan baik daripada dari luar daerah, secara umumnya Pandemi tak terlalu berdampak bagi Papua barat, khususnya Pangan, mungkin bagian lain yg berdampak ketika pamdemi"</i>
                </blockquote>
                </div>
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl xl:mb-0 mb-8 w-full' title='Perubahan Kegiatan selama Pandemi COVID-19'>
            <div className='xl:flex w-full'>
              <div  style={{flex: 1}}>
                <h4 className="text-black leading-[25px] text-center">Kegiatan mendapatkan makanan</h4>
                <div style={{ width: '420px', height: '500px'}}><KegiatanEkonomiPintar data={filteredData} /></div>
              </div>
              <div  style={{flex: 1}}>
                <h4 className="text-black leading-[25px] text-center">Kegiatan transaksi makanan di berbagai lokasi</h4>
                <div style={{ width: '400px', height: '500px'}}><KegiatanTransaksiMakanan data={filteredData} /></div>
              </div>
              <div  style={{flex: 1}}>
                <h4 className="text-black leading-[25px] text-center">Kegiatan konsumsi makanan dengan berbagai metode</h4>
                <div style={{ width: '400px', height: '500px'}}><KegiatanMetodeMakanan data={filteredData} /></div>
              </div>
              </div>
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <div className='xl:flex w-full'>
                <Card classes='xl:w-[100%]  xl:mb-0 mb-8' title='Adaptasi Keluarga dalam Menghadapi COVID-19*'>
                  <AdaptasiKeluarga1 data={filteredData} />
                  <AdaptasiKeluarga2 data={filteredData} />
                  <div className='prose mt-4' style={{fontSize: 12, maxWidth: '100%'}}>
                    *: Jumlah responden yang menjawab “Adaptasi Keluarga dalam Menghadapi COVID-19” sebanyak 402 responden (OAP= 232 dan Non OAP=170; Laki-laki=186 dan Perempuan=216).
                  </div>
                </Card>
              </div>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <div className='xl:flex w-full'>
                <Card classes='xl:w-[100%]  xl:mb-0 mb-8' title='Kondisi semenjak Pandemi COVID-19 dalam Membeli Makanan'>
                  <KondisiMembeliMakanan data={filteredData} />
                </Card>
              </div>
          </div>

          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[50%] xl:mb-0 mb-8 w-full'>
              <div className='block xl:flex gap-8 w-full'>
                  {/* <img src="/Picture2.jpg" alt="image" /> */}
                <blockquote className='prose'>
                Seorang Pekerja perempuan dari Manokwari memberikan <b>opini terkait upaya yang diperlukan dalam memperbaiki ketahanan pangan di Papua Barat</b>, yaitu dengan cara: <i>"Mengembangkan komoditi tanaman pangan khususnya komoditi tanaman pangan yang banyak menjadi konsumsi lokal seperti ubi-ubian serta sayuran yg bisa di tanaman di pekarangan rumah dengan memanfaatkan pekarangan rumah dan menggunakan bahan-bahan yang ramah lingkungan"</i>
                </blockquote>
                </div>
            </Card>
            <Card classes='xl:w-[50%] xl:mb-0 mb-8 w-full'>
              <div className='block xl:flex gap-8 w-full'>
                  {/* <img src="/Picture2.jpg" alt="image" /> */}
                <blockquote className='prose'>
                Seorang Petani laki-laki dari Pegunungan Arfak juga beropini: <i>"Menurut saya pemerintah perhatikan masyarakat petani disini, upayakan agar bagamana supaya hasil kebun kami bisa semakin meningkat dan lebih baik."</i>
                </blockquote>
                </div>
            </Card>
          </div>
          <div className='text-stone-800 text-lg font-bold leading-[18px] mb-[34px]' style={{fontSize: 22, color : '#013b09'}}>
            Lingkungan Pangan
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[100%] xl:mb-0 mb-8 w-full' title='Bahan Bakar Sebelum Pandemi dan Perubahan Akses Selama Pandemi'>
              <div className='xl:flex w-full'>
                <div  style={{flex: 1}}>
                  <h5 className="text-black leading-[25px] text-center">Bahan Bakar sebelum Pandemi*</h5>
                  <BahanBakarBar data={filteredData} />
                  <div className='prose mt-4' style={{fontSize: 12}}>
                    *: Bahan bakar apa yang sering digunakan dirumah sebelum pandemi.
                  </div>
                </div>
                <div  style={{flex: 1}}>
                  <h5 className="text-black leading-[25px] mb-8 text-center">Perubahan Akses Bahan Bakar selama Pandemi</h5>
                <BahanBakarDonut data={filteredData} />
                </div>
              </div>
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[100%] xl:mb-0 mb-8 w-full' title='Frekuensi* dan Jenis Media Iklan** Makanan selama Pandemi'>
              <div className='xl:flex w-full'>
                <div  style={{flex: 1}}>
                <FrekuensiBar data={filteredData} />
                  <div className='prose mt-4' style={{fontSize: 12}}>
                    *: Seberapa sering media mengiklankan makanan/minuman selama pandemi.
                  </div>
                </div>
                <div  style={{flex: 1}}>
                <FrekuensiMedia data={filteredData} />
                  <div className='prose mt-4' style={{fontSize: 12}}>
                    **: Media yang giat mengiklankan produk makanan/minuman selama pandemi
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[100%] xl:mb-0 mb-8 w-full' title='Bantuan selama COVID-19*'>

            <Card classes="xl:w-[100%]">
              <div>
                <h4 className="text-black font-bold leading-[25px]">Yang mendapat bantuan</h4>
                <div><span className="text-[#048737] font-extrabold text-[100px]">{(filteredData.filter(item => item["bantuan"] == 'yes').length/filteredData.filter(item => item["bantuan"] !== undefined).length* 100).toFixed(1)}</span><span className="font-bold">%</span></div>
                <div><span className={'p-1 text-[12px] rounded-[2px] text-white ' + ((parseFloat(200) < 50) ? 'bg-[#F36242]' : 'bg-[#6DDE6B]')}>{(filteredData.filter(item => item["bantuan"] == 'no').length/filteredData.filter(item => item["bantuan"] !== undefined).length* 100).toFixed(1)}%</span> <span className="text-[#A0A0A0] text-[12px]">tidak mendapat bantuan</span></div>
              </div>
            </Card>
              <div className='xl:flex w-full'>
                <div  style={{flex: 1}}>
                  <h5 className="text-black leading-[25px] text-center">Pemberi Bantuan</h5>
                  <PemberiBantuan data={filteredData}  unfilteredData={data}/>
                </div>
                <div  style={{flex: 1}}>
                  <h5 className="text-black leading-[25px] text-center">Bentuk Bantuan</h5>
                <BentukBantuan data={filteredData} />
                </div>
                <div  style={{flex: 1}}>
                  <h5 className="text-black leading-[25px] text-center">Dampak Bantuan</h5>
                <DampakBantuan data={filteredData} />
                </div>
              </div>
                  <div className='prose mt-4' style={{fontSize: 12}}>
                    *: Jumlah responden pada grafik Pemberi Bantuan; Bentuk Bantuan; dan Dampak Bantuan adalah sejumlah responden yang menerima bantuan yaitu sebanyak 187 responden (OAP= 116 dan Non OAP= 71; Laki-Laki=73 dan Perempuan 114).
                  </div>
            </Card>
          </div>
        </>
      }
    </LoadingOverlay>
  );
}

