import { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
import Card from '../components/misc/Card';
import AsalKabupaten from '../components/data-graph/produsen-pangan/AsalKabupaten';
import { Link } from 'react-router-dom';
import TingkatPendidikan from '../components/data-graph/produsen-pangan/TingkatPendidikan';
import UsiaDalamTahun from '../components/data-graph/produsen-pangan/UsiaDalamTahun';
import JumlahAnggotaKeluarga from '../components/data-graph/produsen-pangan/JumlahAnggotaKeluarga';
import KomoditasProduksi from '../components/data-graph/produsen-pangan/KomoditasProduksi';
import JumlahDanLokasiProduksi from '../components/data-graph/produsen-pangan/JumlahDanLokasiProduksi';
import TransportasiKeLokasiProduksi from '../components/data-graph/produsen-pangan/TransportasiKeLokasiProduksi';
import PemilikLahan from '../components/data-graph/produsen-pangan/PemilikLahan';
import PelakuProduksi from '../components/data-graph/produsen-pangan/PelakuProduksi';
import Button from '../components/misc/Button';
import PerubahanPekerja from '../components/data-graph/PerubahanPekerja';
import PerubahanPendapatan from '../components/data-graph/produsen-pangan/PerubahanPendapatan';
import SumberPendapatan from '../components/data-graph/produsen-pangan/SumberPendapatan';
import InputProduksi from '../components/data-graph/produsen-pangan/InputProduksi';
import TargetPembeli from '../components/data-graph/produsen-pangan/TargetPembeli';
import JarakPasarTerdekat from '../components/data-graph/produsen-pangan/JarakPasarTerdekat';
import PerubahanPendapatanCovid from '../components/data-graph/PerubahanPendapatanCovid';
import PenggunaanPangan from '../components/data-graph/PenggunaanPangan';
import ProduksiPangan from '../components/data-graph/ProduksiPangan';
import AksesSumberDaya from '../components/data-graph/AksesSumberDaya';
import HargaJual from '../components/data-graph/HargaJual';
import Kesulitan from '../components/data-graph/Kesulitan';
import AdaptasiCovid from '../components/data-graph/AdaptasiCovid';
import SumberInformasi from '../components/data-graph/produsen-pangan/SumberInformasi';
import BantuanCovid from '../components/data-graph/BantuanCovid';
import PemberiBantuan from '../components/data-graph/produsen-pangan/PemberiBantuan';
import BentukBantuan from '../components/data-graph/produsen-pangan/BentukBantuan';
import ManfaatHutan1 from '../components/data-graph/produsen-pangan/ManfaatHutan1';
import ManfaatHutan2 from '../components/data-graph/produsen-pangan/ManfaatHutan2';
import DampakBantuan from '../components/data-graph/DampakBantuan';
import PemasaranOnline from '../components/data-graph/PemasaranOnline';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import PercentPenurunanHarga from '../components/data-graph/produsen-pangan/PercentPenurunanHarga';
import LoadingOverlay from 'react-loading-overlay';
import { ClipLoader } from 'react-spinners';

export default function SurveyProdusenPangan() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [filterJk, setFilterJk] = useState(null) // perempuan
  const [filterSuku2, setFilterSuku2] = useState(null) // OAP

  useEffect(() => { (async() => {
    const f = await fetch("/Final_DB_Survey 2_PB.xlsx");
    const ab = await f.arrayBuffer();
    const wb = read(ab);
    const ws = wb.Sheets[wb.SheetNames[1]];
    const rawData = utils.sheet_to_json(ws);
    console.log('raw data: ', rawData)
    setLoading(false)
    setData(rawData)
  })(); }, [])

  useEffect(() => {
    const rawData = data.filter(item => {
      // return item.jenis_kelamin === filterJk && item.kategori_suku === filterSuku2
      if (filterJk && filterSuku2) {
        return item.jenis_kelamin === filterJk && item.kategori_suku === filterSuku2
      } else if (filterJk && !filterSuku2) {
        return item.jenis_kelamin === filterJk
      } else if (!filterJk && filterSuku2) {
        return item.kategori_suku === filterSuku2
      } else {
        return item
      }
    })
    console.log('filtered data: ', rawData)
    setFilteredData(rawData)
  }, [data, filterJk, filterSuku2]);

  return (
    <LoadingOverlay
    active={loading}
    spinner={<ClipLoader color="#36d7b7" size="70"/>}
    >
      <h2 className="text-stone-800 mb-[25px] text-[39px] font-bold">Survei Produsen Pangan</h2>
      <div className='xl:flex w-full mb-[77px]'>
        <div className="w-full text-neutral-700 mb-8 xl:mb-0 text-[17px] leading-7 xl:w-[70%]">
          <p>
          Survei produsen pangan ini dilakukan kepada semua orang/individu yang terlibat secara langsung dalam proses produksi (menghasilkan, mengadakan, menangkap, membudidayakan, menumbuhkan, mengumpulkan, membuat) pangan mentah dan segar.
          </p>
          <p>
          Survei ini bertujuan agar pemerintah dapat mengkaji hal berikut:
          </p>
          <ol>
            <li>Efek pandemi pada kegiatan produksi pangan dan usaha produksi pangan </li>
            <li>Strategi produsen dalam menghadapi efek pandemi </li>
            <li>Faktor-faktor yang dapat memperbaiki produksi pangan dan kesejahteraan produsen</li>
          </ol>
          <p>Keluaran dari survei ini diharapkan dapat memberikan informasi kepada pemerintah sehingga dapat merencanakan kebijakan/program yang membantu produsen utama pangan di Provinsi Papua Barat.  </p>
<p>Survei ini dilaksanakan secara offline pada bulan November 2020 sampai dengan Mei 2021. Kami melakukan wawancara dengan mengunjungi responden di lokasi produksi dan beberapa lokasi seperti: pasar, distributor, warung makan, kios, restoran, kafe, peternakan, dll, untuk meminta produsen pangan berpartisipasi dalam survei. Total responden dalam survei produsen pangan sebanyak 401 responden (OAP=290 dan Non OAP=111; Laki-laki=190 dan Perempuan=211).
</p>
        </div>
        <div className='w-full text-black text-sm font-normal leading-[25px] xl:pl-[80px] xl:w-[30%]'>
          <Tooltip 
          id="my-tooltip"
          className={'opaque'}
          style={{ backgroundColor: "#ffffff", color: "#222", opacity: 1 }}>
            <p className='mb-4 text-base'>Cara menggunakan infografis:</p>
            <div className='prose'>
            <ol>
              <li>Setiap kelompok infografis menggambarkan setiap tujuan dalam survei </li>
              <li>Tombol jenis kelamin dan kategori suku merupakan filter yang dapat digunakan jika ingin mengetahui persentase untuk masing-masing pilihan jenis kelamin dan atau kategori suku. OAP adalah Orang Asli Papua dan Non OAP adalah bukan Orang Asli Papua</li>
              <li>Setiap filter yang dipilih, maka data yang ditampilkan adalah persentase berdasarkan jumlah OAP/non OAP/laki-laki/perempuan. </li>
            </ol>
            </div>
          </Tooltip>
          <button className="button w-full" data-tooltip-id="my-tooltip">PANDUAN PENGGUNAAN</button>
        </div>
      </div>

      <div className='xl:flex w-full gap-x-12 py-8 mb-8 sticky bg-[#F5F5F5] top-[105px] md:top-[72px] z-10 border-t border-t-zinc-300'>
        <div className='flex gap-4 mr-24'>
          <div className='font-bold'>Suku</div>
          <div className='form-control'>
            <input checked={filterSuku2 === 'Non OAP'} className='mr-1' type='radio' name='suku' id='non-oap' value='Non OAP' onChange={(e) => setFilterSuku2(e.target.value)} />
            <label htmlFor="non-oap">Non OAP</label>
          </div>
          <div className='form-control'>
            <input checked={filterSuku2 === 'OAP'} className='mr-1' type='radio' name='suku' id='oap' value='OAP' onChange={(e) => setFilterSuku2(e.target.value)} />
            <label htmlFor="oap">OAP</label>
          </div>
        </div>
        <div className='flex gap-4'>
          <div className='mr-1 font-bold'>Jenis kelamin</div>
          <div className='form-control'>
            <input checked={filterJk === 'Laki-laki'} className='mr-1' type='radio' name='filter-jk' id='Laki-laki' value='Laki-laki' onChange={(e) => setFilterJk(e.target.value)} />
            <label htmlFor="Laki-laki">Laki-laki</label>
          </div>
          <div className='form-control'>
            <input checked={filterJk === 'Perempuan'} className='mr-1' type='radio' name='filter-jk' id='Perempuan' value='Perempuan' onChange={(e) => setFilterJk(e.target.value)} />
            <label htmlFor="Perempuan">Perempuan</label>
          </div>
        </div>
        <div className='grow'></div>
        {
          (filterJk || filterSuku2) &&
          <Button
              style={{ padding: '10px', fontSize: '10px' }}
              onClick={() => {
                setFilterJk(null)
                setFilterSuku2(null)
              }
            }>
            Reset
          </Button>
        }
      </div>

      {!loading && 
        <>
          <div className='text-stone-800 text-lg font-bold leading-[18px] mb-[34px]'>
            Demografi
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[65%] xl:mb-0 mb-8 w-full' title='Asal Kabupaten*'>
              <AsalKabupaten data={filteredData} />
              <div className='prose' style={{fontSize: 12}}>
                *: Asal kabupaten merupakan tempat tinggal responden saat wawancara
              </div>
              <Card classes='' title='Tingkat Pendidikan' style={{padding: "30px 0"}}>
                <TingkatPendidikan data={filteredData} />
              </Card>
            </Card>
            <div className='xl:w-[35%] xl:mb-0 mb-8 w-full'>

              <Card title='Usia (dalam tahun)' classes='mb-8'>
                <UsiaDalamTahun data={filteredData} />
              </Card>
              <Card title='Jumlah Anggota Keluarga*'>
                <JumlahAnggotaKeluarga width={100} data={filteredData} />
              <div className='prose mt-4' style={{fontSize: 12}}>
                *: Responden yang menyebutkan jumlah anggota keluarga sebanyak 398 responden (OAP=288 dan Non OAP=110; Laki-laki=187 dan Perempuan=211).
              </div>
              </Card>
            </div>
          </div>

          <div className='text-stone-800 text-lg font-bold leading-[18px] mb-[34px]' style={{fontSize: 22, color : '#013b09'}}>
            Usaha Produksi Pangan
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[33%]' title='Komoditas Produksi*'>
              <KomoditasProduksi data={filteredData} />
              <div className='prose mt-4' style={{fontSize: 12}}>
                *: Komoditas Produksi adalah jenis pangan apa saja yang  diproduksi oleh responden (bisa pilih lebih dari satu jawaban untuk satu responden).
              </div>
            </Card>
            <Card classes='xl:w-[33%]' title='Jumlah & Lokasi Produksi*'>
              <JumlahDanLokasiProduksi data={filteredData} />
              <div className='prose mt-4' style={{fontSize: 12}}>
                *: Lokasi Produksi adalah kabupaten tempat produksi pangan (bisa lebih dari satu jawaban untuk satu responden).
              </div>
            </Card>
            <Card classes='xl:w-[33%]' title='Transportasi (ke Lokasi Produksi)*'>
              <TransportasiKeLokasiProduksi data={filteredData} />
              <div className='prose mt-4' style={{fontSize: 12}}>
                *:Cara/kendaraan yang digunakan responden menuju ke lokasi produksi
              </div>
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[70%]' title='Pemilik Lahan & Pelaku Produksi'>
              <div className='xl:flex w-full'>
                <div  style={{flex: 1}}>
                  <h4 className="text-black leading-[25px] text-center">Pemilik Lahan</h4>
                  <PemilikLahan data={filteredData} />
                </div>
                <div  style={{flex: 1}}>
                  <h4 className="text-black leading-[25px] text-center">Pelaku Produksi</h4>
                  <PelakuProduksi data={filteredData} />
                </div>
              </div>
            </Card>
            <div className='xl:w-[30%]'>
              <Card title='Pekerja Diupah*' classes='mb-8'>
                <PerubahanPekerja field={'jumlah_pekerja_dibayar'} trueOrFalse={true} data={filteredData} />
                <div className='prose mt-4' style={{fontSize: 12}}>
                  *: Persentase responden yang memiliki tenaga kerja berupah/gaji.
                </div>
              </Card>
              <Card title='Pekerja Tidak Diupah*'>
                <PerubahanPekerja field={'jumlah_pekerja_tak_dibayar'} trueOrFalse={true} data={filteredData} />
                <div className='prose mt-4' style={{fontSize: 12}}>
                  *: Persentase responden yang memiliki tenaga kerja namun tidak berupah/gaji (misalnya anggota keluarga).
                </div>
              </Card>
            </div>
          </div>

          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[100%]' title='Rata-rata Pendapatan*'>
              <PerubahanPendapatan data={filteredData} />
              <div className='prose mt-4' style={{fontSize: 12, maxWidth: '100%'}}>
                *: Rata-rata pendapatan adalah  jumlah pendapatan bersih rata-rata selama satu bulan dari usaha produksi pangan sebelum pandemi COVID-19.
              </div>
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[60%]' title='Sumber Pendapatan*'>
              <div className='xl:flex w-full'>
                <div style={{flex: 1}}>
                  <SumberPendapatan data={filteredData} />
                  <div className='prose mt-4' style={{fontSize: 12}}>
                    *: Sumber pendapatan responden dapat lebih dari satu.
                  </div>
                </div>
              </div>
            </Card>
            <Card classes='xl:w-[40%]' title='Perubahan Pendapatan akibat COVID-19'>
              <PerubahanPendapatanCovid data={filteredData} />
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[33%]' title='Input Produksi*' >
              <InputProduksi data={filteredData}/>
              <div className='prose mt-4' style={{fontSize: 12}}>
                *: Input Produksi adalah cara responden mendapatkan bahan/sarana untuk produksi pangan (benih, pupuk, pestisida, peralatan, dll).
              </div>
            </Card>
            <Card classes='xl:w-[33%] shrink' title='Target Pembeli*'>
              <TargetPembeli data={filteredData} unfilteredData={data}/>
              <div className='prose mt-4' style={{fontSize: 12}}>
                *: Jumlah responden yang menjawab adalah adalah 380 responden (OAP=278 dan Non OAP=102; Laki-laki=174 dan Perempuan=206).
              </div>
            </Card>
            <Card classes='xl:w-[33%] shrink' title='Jarak Pasar Terdekat (dalam Jam)'>
              <JarakPasarTerdekat data={filteredData} />
            </Card>
          </div>
          <Card title='Rata-Rata Penggunaan Hasil Produksi Pangan*'>
            <div className="xl:flex">
              <div className='xl:w-[25%]'>
                <Card title='Digunakan sendiri'>
                  <PenggunaanPangan field={"penggunaan_hasil_produksi_digunakan_sendiri"} trueOrFalse={true} data={filteredData} />
                </Card>
              </div>
              <div className='xl:w-[25%]'>
                <Card title='Barter'>
                  <PenggunaanPangan field={"penggunaan_hasil_produksi_barter"} trueOrFalse={true} data={filteredData} />
                </Card>
              </div>
              <div className='xl:w-[25%]'>
                <Card title='Disumbangkan'>
                  <PenggunaanPangan field={"penggunaan_hasil_produksi_disumbangkan"} trueOrFalse={true} data={filteredData} />
                </Card>
              </div>
              <div className='xl:w-[25%]'>
                <Card title='Dijual'>
                  <PenggunaanPangan field={"penggunaan_hasil_produksi_dijual"} trueOrFalse={true} data={filteredData} />
                </Card>
              </div>
              <div className='xl:w-[25%]'>
                <Card title='Terbuang'>
                  <PenggunaanPangan field={"penggunaan_hasil_produksi_terbuang"} trueOrFalse={true} data={filteredData} />
                </Card>
              </div>
            </div>
              <div className='prose' style={{fontSize: 12}}>
                *: Jumlah responden yang menjawab adalah sebanyak 394 responden (OAP=285 dan Non OAP=109; Laki-laki=183 dan Perempuan=211).
              </div>
          </Card>

          <div className='text-stone-800 text-lg font-bold leading-[18px] mb-[34px] mt-8' style={{fontSize: 22, color : '#013b09'}}>
            Dampak COVID-19
          </div>
          <div className='block xl:flex gap-8 w-full mb-8' style={{background: '#fff'}}>
            <div className='xl:w-[50%] xl:mb-0 mb-8 w-full'>
              <Card title='Dampak terhadap Usaha Produksi Pangan*'>
                <ProduksiPangan width={100} data={filteredData} />
                <div className='prose' style={{fontSize: 12}}>
                  *: Jumlah responden yang menjawab "Dampak COVID-19 Terhadap Usaha Produksi Pangan" sebanyak 398 responden (OAP=288 dan Non OAP=110; Laki-laki=189 dan Perempuan=209).
                </div>
              </Card>
            </div>
            <div className='xl:w-[50%] xl:mb-0 mb-8 w-full'>
              <Card title='Kesulitan akibat COVID-19*'>
                <Kesulitan data={filteredData} unfilteredData={data} />
                <div className='prose' style={{fontSize: 12}}>
                *: Persentase responden dalam "Kesulitan akibat COVID-19" adalah persentase terhadap total responden yang mengalami “Kesulitan” dan “Kesulitan & Kemudahan” sebanyak 343 responden (OAP=248 dan Non OAP=95; Laki-laki=154 dan Perempuan=189).
                <br></br>**: Harga bahan termasuk harga bahan baku dan/atau bahan pendukung
                <br></br>***: Lainnya termasuk mengadakan pekerja dan/atau lainnya
                </div>
              </Card>
            </div>
          </div>

          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[50%] xl:mb-0 mb-8 w-full'>
              <div className='block xl:flex gap-8 w-full'>
                  {/* <img src="/Picture2.jpg" alt="image" /> */}
                <blockquote className='prose'>
                Seorang petani perempuan dari Pegunungan Arfak mengeluh: <i>"Kalau awal-awal pandemi yang mungkin agak sulit, karena kabupaten manokwari lockdown sehingga tidak ada transportasi untuk bawa jualan ke manokwari, sehingga hasil kebun kami kadang tersimpan dan busuk, namun kami manfaatkan utuk jadi makanan ternak kami (babi)."</i>
                </blockquote>
                </div>
            </Card>
            <Card classes='xl:w-[50%] xl:mb-0 mb-8 w-full'>
              <div className='block xl:flex gap-8 w-full'>
                  {/* <img src="/Picture2.jpg" alt="image" /> */}
                <blockquote className='prose'>
                Seorang peternak laki-laki dari Manokwari juga mengeluh: <i>"Problem yang di alami hanya harga persaingan telur dari luar Manokwari, persaingan antar Produsen dari Fulica dengan Produsen ternak ayam yang kecil, dan tidak adanya tempat penjualan daging ternak babi agar pemasarannya lebih lancar sesuai permintaan pasar setiap hari dengan jumlah yang berbedah-bedah"</i>
                </blockquote>
                </div>
            </Card>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl:w-[100%] xl:mb-0 mb-8 w-full'>
              <div className='xl:flex'>
                <div style={{flex: 1}}>
                  <h4 className="text-black leading-[25px] text-bold text-center">Akses Sumber Daya selama COVID-19*</h4>
                  <div style={{ width: '350px', height: '350px', margin:"10px auto" }}><AksesSumberDaya width={100} data={filteredData} /></div>
                </div>
                <div style={{flex: 1}}>
                  <h4 className="text-black leading-[25px] text-bold text-center">Harga Jual selama COVID-19**</h4>
                  <div style={{ width: '350px', height: '350px', margin:"10px auto" }}><HargaJual width={100} data={filteredData} /></div>
                </div>
                <div style={{flex: 1}}>
                  <h4 className="text-black leading-[25px] text-bold text-center">Persen Penurunan Harga Jual***</h4>
                  <div style={{ width: '350px', height: '350px', margin:"10px auto" }}><PercentPenurunanHarga data={filteredData} /></div>
                </div>
              </div>
                <div className='prose mt-4' style={{fontSize: 12, maxWidth:"100%"}}>
                  *: Akses Sumberdaya (N=391; OAP=284 dan Non OAP=107; Laki-laki=185 dan Perempuan=206) adalah akses untuk mendapatkan bahan/sarana produksi pangan (benih, pupuk, pestisida, peralatan, dll) di masa pandemi COVID-19.
                  <br></br>**: Harga jual (N=366; OAP=270 dan Non OAP=96; Laki-laki=168 dan Perempuan=198) adalah bagaimana kecenderungan situasi harga produk pangan selama pandemi (jika responden menjual produk).
                  <br></br>***: Persen penurunan harga jual (N=134; OAP=98 dan Non OAP=36; Laki-laki=52 dan Perempuan=82) adalah banyaknya persentase penuruan dari responden yang melaporkan mengalami penurunan harga jual selama pandemi.
                </div>
            </Card>
          </div>

          <Card title='Adaptasi selama COVID-19*'>
            <AdaptasiCovid data={filteredData} />
              <div className='prose mt-4' style={{fontSize: 12, maxWidth:"100%"}}>
                *: Adaptasi selama COVID-19 adalah penyesuaian usaha produksi pangan yang dilakukan responden akibat adanya pandemi COVID-19.
                <br></br>**: Lainnya termasuk strategi dalam (dan atau) menyesuaikan jumlah/biaya pekerja, pemasaran, serta modal.
              </div>
          </Card>
          <div className='text-stone-800 text-lg font-bold leading-[18px] mb-[34px] mt-8' style={{fontSize: 22, color : '#013b09'}}>
            Faktor yang Memperbaiki produksi pangan dan kesejahteraan produsen
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <div className='xl:w-[35%] xl:mb-0 mb-8 w-full'>
              <Card title='Sumber Informasi*'>
              <div style={{ height: '350px' }}><SumberInformasi data={filteredData} /></div>
                <div className='prose mt-4' style={{fontSize: 12, maxWidth:"100%"}}>
                  *: Sumber informasi adalah sumber informasi dan pengetahuan yang didapatkan responden tentang usaha produksi pangan.
                </div>
              </Card>
            </div>
            <div className='xl:w-[65%] xl:mb-0 mb-8 w-full'>
              <Card title='Pemasaran Online*'>
                <div className='block xl:flex gap-8 w-full'>
                  <div style={{ width: '300px', height: '350px' }}><PemasaranOnline data={filteredData} /></div>
                  <div className='block gap-8 w-full'>
                    {/* <img src="/Picture2.jpg" alt="image" /> */}
                    <blockquote className='prose'>
                    Seorang petani perempuan dari Sorong mengungkapkan pengaruh pemasaran online terhadap usaha produksi pangan: <i>"Sangat berpengaruh, kalo kita jual lewat media sosial lebih cepat dapat pembeli dari pada kita harus duduk jualan di pondok atau antar lagi ke pasar dan lainnya"</i>
                    </blockquote>
                    <blockquote className='prose mt-4'>
                    Seorang petani laki-laki dari Manokwari juga mengungkapkan: <i>"Produk pangan lebih cepat habis dan bahkan permintaan pasar lebih besar di banding hasil produksi."</i>
                    </blockquote>
                  </div>
                </div>
                <div className='prose mt-4' style={{fontSize: 12}}>
                  *: Pemasaran online menunjukkan apakah produk pangan pernah dipasarkan secara online (dengan whatsapp/ applikasi lain) oleh pihak manapun.
                </div>
              </Card>
            </div>
          </div>
          <div className='block xl:flex gap-8 w-full mb-8'>
            <Card classes='xl xl:mb-0 mb-8 w-full'>
              <div className='xl:flex'>
                <div style={{flex: 1}}>
                  <div className='text-stone-800 text-md font-bold '>
                    Bantuan selama COVID-19*
                  </div>
                  <div style={{ width: '350px', height: '350px', margin:"10px auto" }}><BantuanCovid width={100} data={filteredData} /></div>
                </div>
                <div style={{flex: 1}}>
                  <div className='text-stone-800 text-md font-bold '>
                    Dampak Bantuan**
                  </div>
                  <div style={{ width: '350px', height: '350px', margin:"10px auto" }}><DampakBantuan width={100} data={filteredData} /></div>
                </div>
              </div>
              <div className='xl:flex'>
                <div style={{flex: 1}}>
                   <div className='text-stone-800 text-md font-bold mt-8'>
                    Pemberi Bantuan***
                  </div>
                  <div style={{ width: '450px',height: '350px', margin:"10px auto" }}><PemberiBantuan data={filteredData} /></div>
                </div>
                <div style={{flex: 1}}>
                  <div className='text-stone-800 text-md font-bold  mt-8'>
                  Bentuk Bantuan***
                  </div>
                  <div style={{ width: '450px',height: '350px', margin:"10px auto" }}><BentukBantuan width={100} data={filteredData} /></div>
                </div>
              </div>

              <div className='prose mt-4' style={{fontSize: 12, maxWidth: '100%'}}>
                  *: Bantuan selama COVID-19 (N=401; OAP=290 dan Non OAP=111; Laki-laki=190 dan Perempuan=211) menunjukkan apakah pernah menerima bantuan usaha semenjak pandemi COVID-19.
                  <br></br>**: Dampak bantuan (N=119; OAP=101 dan Non OAP=18; Laki-laki=41 dan Perempuan=78) adalah jawaban dari responden yang "Mendapat Bantuan"
                  <br></br>***: Pemberi bantuan dan Bentuk bantuan (N=125; OAP=106 dan Non OAP=19; Laki-laki=44 dan Perempuan=81) adalah jawaban dari responden yang "Mendapat Bantuan" dan "Kemungkinan akan dapat bantuan".
              </div>
            </Card>
          </div>
          <Card classes='xl xl:mb-0 mb-8 w-full' title='Manfaat Hutan dalam Produksi Pangan*'>
            <p>Manfaat yang dirasakan oleh produden pangan yang berada di dekat hutan dalam menunjang usaha produksi pangan.</p>
              <div className='xl:flex'>
                <div style={{flex: 1}}>
                  <ManfaatHutan1 width={100} data={filteredData} />
                </div>
                <div style={{flex: 1}}>
                  <ManfaatHutan2 width={100} data={filteredData} />
                </div>
              </div>
              <div className='prose mt-4' style={{fontSize: 12, maxWidth: '100%'}}>
                *: Manfaat hutan (N=389; OAP=287 dan Non OAP=102; Laki-laki=182 dan Perempuan=207) menunjukkan apakah responden merasakan manfaat hutan dalam menunjang usaha produksi pangan (jika lokasi produksi pangan dekat hutan).
              </div>
            </Card>
        </>
      }
    </LoadingOverlay>
  );
}

